<template>
    <div class="relative gm-padding-y">

        <div class="container">
            <div class=" shadow border rounded-lg">
                <div class="grid lg:grid-cols-2">

                    <div class="grid grid-cols-6 gap-6 py-12 pl-6 max-lg:h-[300px] overflow-auto">
                        <div>
                            <ol class="space-y-4 flex items-center flex-col">
                                <template v-for="(item,x) in items">
                                    <li
                                        :class="computedClass(item, x)"
                                        class="text-2xl font-bold p-1 flex items-center justify-center size-8 lg:size-16 rounded-lg transition duration-300 cursor-pointer hover:bg-primary/10 hover:text-primary"
                                        @click="currentItem = x"
                                    >
                                        {{ x + 1 }}
                                    </li>
                                    <li
                                        v-if="x+1 !== items.length"
                                        class="bg-gray-500/10 text-2xl font-bold p-1 flex items-center justify-center size-4 lg:size-8 rounded-lg">
                                        &centerdot;
                                    </li>
                                </template>
                            </ol>
                        </div>
                        <div class="col-span-5">
                            <h4>
                                {{ items[currentItem].title }}
                            </h4>

                            <div class="mt-6" v-html="items[currentItem].content">
                            </div>
                        </div>
                    </div>

                    <div class="relative py-12 bg-gray-50 flex items-center justify-center">
                        <div
                            class="inset-0 w-full h-full absolute bg-gradient-to-l from-white to-transparent z-10 opacity-10"></div>

                        <div class="aspect-[3/2] shadow bg-white rounded-2xl overflow-hidden p-2 w-[80%]">
                            <img :src="items[currentItem].image" :alt="items[currentItem].title"
                                 class="object-cover w-full h-full rounded-xl"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script setup>
import {computed, onUnmounted, ref} from "vue";

const props = defineProps(['items'])

const currentItem = ref(0)

const interval = setInterval(() => {
    currentItem.value = (currentItem.value + 1) % props.items.length
}, 10000)

onUnmounted(() => {
    clearInterval(interval)
});

const computedClass = (item, x) => {
    if (currentItem.value === x) {
        return `${item.color ?? 'bg-primary/10 text-primary'}`;
    } else {
        return 'bg-gray-500/10 text-gray-500';
    }
};
</script>
