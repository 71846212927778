<template>
    <FrontendLayout title="The Ultimate A.I. Call Platform That Connects, Engages, and Converts.">
        <WelcomeHero/>

        <HomepageVideo/>

        <IntroSlogan/>

        <!--        <NumCalls :calls="calls"/>-->
        <Voices/>

        <ForEveryone/>

        <FeatureBox :items="featureItems"/>

        <FeatureTabs/>

        <DiscoverGreetmate/>

        <FeatureCards/>

        <Integrations/>

        <Pricing/>

    </FrontendLayout>
</template>
<script setup lang="ts">
import FrontendLayout from "@/Layouts/FrontendLayout.vue";
import WelcomeHero from "@/Pages/Index/WelcomeHero.vue";
import Voices from "@/Pages/Index/Voices.vue";
import FeatureTabs from "@/Pages/Index/FeatureTabs.vue";
import Pricing from "@/Pages/Index/Pricing.vue";
import HomepageVideo from "@/Pages/Index/HomepageVideo.vue";
import IntroSlogan from "@/Pages/Index/IntroSlogan.vue";
import DiscoverGreetmate from "@/Pages/Index/DiscoverGreetmate.vue";

import calls from '@/../images/calls.jpg?w=1900&format=webp';
import phonenumber from '@/../images/phone-number.jpg?w=1000&format=webp';
import instructions from '@/../images/instructions.jpg?w=1000&format=webp';
import workflow1 from '@/../images/workflow1.jpg?w=1000&format=webp';

import ForEveryone from "@/Pages/Index/ForEveryone.vue";
import FeatureCards from "@/Pages/Index/FeatureCards.vue";
import FeatureBox from "@/Components/Marketing/FeatureBox.vue";
import Integrations from "@/Pages/Index/Integrations.vue";


defineProps(['calls'])

const featureItems = [
    {
        title: 'Let Greetmate do the Calling for You and Save Hundreds of Hours.',
        content: 'No time to take all your calls? Let Greetmate do the hard work for you. Greetmate can handle all your calls, answer questions, take orders, and even transfer calls to you when needed. It operates 24/7 and never gets tired.',
        image: phonenumber,
        color: 'bg-green-500/10'
    },
    {
        title: 'Qualify Callers and Filter out Spam.',
        content: 'Greetmate can ask questions to qualify your callers, filter out spam calls, and even send the information to your CRM or other systems. You can even use the information to route calls to the right person or department.',
        image: instructions
    },
    {
        title: 'A Fraction of the Cost of a Human Agent.',
        content: 'Greetmate can handle all your calls at a fraction of the cost of a human agent.',
        color: 'bg-yellow-500/10',
        image: workflow1
    },
]

</script>
